<template>
    <div>
        <p class="text-center text-md-left secondary--text settings-title subtitle-1 font-weight-medium mt-md-1 mb-1">
            <v-icon class="mr-1 ml-md-1" color="secondary" size="18">fa fa-list</v-icon> Your To-Dos
        </p>
        <v-card class="elevation-2" v-show="showTasks">
            <v-card-text class="pa-0 px-lg-3">
                <v-list class="pt-0 pb-0 px-4 px-lg-0 no-truncate transparent">
                    <v-list-item class="pl-0" to="/app/notes" v-if="!user.notes.length && uxCopy.taskNotes != false">
                        <v-list-item-avatar class="mr-2 mr-lg-1">
                            <v-avatar>
                                <v-icon color="primary">fa fa-notes-medical</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium secondary--text">Add an Emergency Note</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-tooltip color="primary" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click.prevent="hideTask('taskNotes')">fa fa-times-circle</v-icon>
                                </template>
                                Dismiss this task.
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item class="pl-0" to="/app/contacts" v-if="user.contacts.length <= 3 && uxCopy.taskMoreContacts != false">
                        <v-list-item-avatar class="mr-2 mr-lg-1">
                            <v-avatar>
                                <v-icon color="primary">fa fa-address-book</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium secondary--text">
                                <span class="hidden-md-and-up">Add Emergency Contacts</span>
                                <span class="hidden-sm-and-down">Add Another Emergency Contact</span>
                            </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-tooltip color="primary" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click.prevent="hideTask('taskMoreContacts')">fa fa-times-circle</v-icon>
                                </template>
                                Dismiss this task.
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                    <v-list-item class="pl-0" to="/app/account" v-if="!user.image && uxCopy.taskUserImg != false">
                        <v-list-item-avatar class="mr-2 mr-lg-1">
                            <v-avatar>
                                <v-icon color="primary">fa fa-camera</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium secondary--text">Add Your Photo</v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-tooltip color="primary" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon v-on="on" @click.prevent="hideTask('taskUserImg')">fa fa-times-circle</v-icon>
                                </template>
                                Dismiss this task.
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item>
                    <!-- <v-list-item to="/app/addresses" v-if="!user.addresses.length && uxCopy.taskAddresses != false">
                        <v-list-item-avatar>
                            <v-avatar>
                                <v-icon color="primary">fa fa-map-marker-alt</v-icon>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title class="font-weight-medium secondary--text">Add Emergency Addresses</v-list-item-title>
                            <v-list-item-subtitle>Let others know where you might be.</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-tooltip color="primary" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon @click.prevent="hideTask('taskAddresses')" small v-on="on">fa fa-times-circle</v-icon>
                                </template>
                                Dismiss this task.
                            </v-tooltip>
                        </v-list-item-action>
                    </v-list-item> -->
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>
<script>

import { mapState } from 'vuex'

export default {

  name: 'UserDashboardTasks',

    props: {
    },
    computed: {
        ...mapState(['user'])
    },
    data: () => ({
        showTasks: true,
        uxCopy: {},
    }),
    methods: {
        initData(){
            if(this.user.ux){
                this.uxCopy = JSON.parse(this.user.ux);
            }
        },
        hideTask(taskName){ 
            this.uxCopy[taskName] = false;
            let request = {
                endpoint: '/profile', 
                method: 'post',
                data: {
                    _method: 'put',
                    ux: this.uxCopy
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                this.uxCopy = JSON.parse(res.data.ux);
                this.$eventHub.$emit('refreshUser');
            }).finally(()=>{
            });
        },
    },
    mounted(){
        this.initData();
    },
    watch: {
    }
}
</script>
